import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { City } from 'store/City';
import { RaceStore } from './Race';
import { Casts } from 'mobx-spine';
import { PrizeStore } from 'store/Event/Prize';

export class Event extends Model {
    static backendResourceName = 'event';

    @observable id = null;
    @observable name;
    @observable duckPrice = 5.00;
    @observable showInHeader;
    @observable startDate = null;
    @observable raceStart = null;
    @observable endDate = null;
    @observable salesStart = null;
    @observable salesEnd = null;
    @observable claimStart = null;
    @observable claimEnd = null;
    @observable purchaseWidgetUrl = null;
    @observable activationWidgetUrl = null;
    @observable paymentSuccessUrl = null;

    casts() {
        return {
            startDate: Casts.date,
            raceStart: Casts.date,
            endDate: Casts.date,
            salesStart: Casts.datetime,
            salesEnd: Casts.datetime,
            claimStart: Casts.datetime,
            claimEnd: Casts.datetime,
        };
    }

    relations() {
        return {
            city: City,
            races: RaceStore
        }
    }
}

export class PurchaseWidgetEvent extends Event {
    @observable salesClosed = true;

    @observable prizes = this.relation(PrizeStore)
    @observable tickets = []

    fetch() {
        return this.api.get(`/event/${this.id}/details/`,)
            .then((res) => {
                this.name = res['name'];
                this.duckPrice = res['duck_price'];
                this.raceStart = res['race_start'];
                this.startDate = res['event_start'];
                this.claimEnd = res['claim_end']
                this.salesClosed = res['sales_closed']
                this.claimClosed = res['claim_closed']
                this.prizes = new PrizeStore()
                const prizes = res['prizes']
                prizes.forEach(prize => {
                    this.prizes.add({
                        id: prize.id,
                        name: prize.name,
                        description: prize.description,
                        value: prize.value
                    });
                })
                this.tickets = res['tickets']
            })
            .catch(err => {
                    throw err;
                }
            );
    }
}

export class EventStore extends Store {
    static backendResourceName = 'event';
    Model = Event;
}
