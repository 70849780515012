import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { UserStore } from 'store/User';

export class City extends Model {
    static backendResourceName = 'city';

    @observable id = null;
    @observable name = '';
    @observable slug = '';
    @observable logo = null;
    @observable isReseller = false;

    relations() {
        return {
            users: UserStore,
        }
    }
}

export class CityStore extends Store {
    static backendResourceName = 'city';
    Model = City;
}
