import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route, Switch } from 'react-router-dom';
import Load from './Loadable';
import Login from 'spider/semantic-ui/Login';


const NotFound = Load(() => import('../container/NotFound'));

const PurchaseWidget = Load(() => import('screen/PurchaseWidget/Widget'));
const TicketPurchaseWidget = Load(() => import('screen/PurchaseWidget/TicketWidget'));
const PaymentSuccess = Load(() => import('screen/PurchaseWidget/Success'));
const ClaimDuckPage = Load(() => import('screen/ClaimWidget/ClaimDuck'));

const SalesLeaderBoard = Load(() => import('screen/SalesLeaderBoard'));

const ThisPageIsHidden = Load(() => import('screen/Order/ThisPageIsHidden'));




export default class UnauthenticatedRouter extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    // redirectFromHome = () => {
    //     const { currentUser } = this.props.store;
    //     let uri = currentUser.isSuperuser ? (
    //         '/mijninszo/account/details/'
    //     ) : (
    //         '/mijninszo/home/'
    //     );
    //
    //     return <Redirect to={uri}/>;
    // };


    render() {
        return (
            <Switch>
                <Route path="/event/:event/purchase_tickets" render={this.route(TicketPurchaseWidget)}/>
                <Route path="/event/:event/purchase_tickets/:sales_partner" render={this.route(TicketPurchaseWidget)}/>
                <Route path="/event/:event/purchase/:sales_partner" render={this.route(PurchaseWidget)}/>
                <Route path="/event/:event/purchase" render={this.route(PurchaseWidget)}/>
                <Route path="/event/:event/payment_success" render={this.route(PaymentSuccess)}/>
                <Route path="/event/:event/claim" render={this.route(ClaimDuckPage)}/>
                <Route path="/sales-leaderboard/:sales_group/" render={this.route(SalesLeaderBoard)}/>

                {/*<Route path="/login/forgot" render={this.route(LoginForgot)}/>*/}
                {/*<Route path="/login/sso" render={this.route(LoginSso)}/>*/}

                {/*<Route path="/about" render={ this.route(HomeScreen)} />*/}
                {/*<Route path="/product" render={ this.route(ProductScreen)} />*/}

                <Route path="/order/:id/details" render={this.route(ThisPageIsHidden)}/>




                {/*<Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>*/}
                {/*<Route path="/mijninszo/covid_info" render={this.route(CovidInfo)} />*/}
                {/*<Route path="/mijninszo/" render={this.route(Login)} />*/}
                <Route path="/" render={this.route(Login)}/>
                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
