import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import styled from 'styled-components';
import { EventStore } from 'store/Event/Event';
import ViewStore from 'spider/store/View';

// eslint-disable-next-line react/prop-types
const MyLogo = ({ store }) => {
    // eslint-disable-next-line react/prop-types
    const city = store.currentCity
    // eslint-disable-next-line react/prop-types
    if (city.id && city.logo) {
        return (<Logo>
            {/* eslint-disable-next-line react/prop-types */}
            <img src={`/api/city/${city.id}/logo/`} height="35" alt="logo"/>
        </Logo>)
    }
    return (
        <Logo>
            <img src={ImgLogo} style={{ minWidth: '5em' }} height="35" alt="logo"/>
        </Logo>
    );
}

const StyledMenuRow2 = styled(MenuRow)`
    background-color: #fd0 !important;
    color: black !important;

`;
const StyledMenuRow = styled(MenuRow)`
    background-color: #1c1c1c !important;
    color: white !important;
    a::before {
        border-bottom-color: #fd0;
    !important;
    }
`;

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(ViewStore).isRequired,
        location: PropTypes.object.isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    @observable activeEvents = new EventStore();
    @observable shownEvents = this.activeEvents.models;

    componentDidMount() {
        this.activeEvents.params['.show_in_header'] = true;
        reaction(() => this.props.store.isAuthenticated,
            (authenticated) => {
                if (authenticated) {
                    this.activeEvents.fetch();
                }
            },
            { fireImmediately: true }
        );

    }

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    };

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAssets = () => {
        const { store } = this.props;
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                {/*<NavItem*/}
                {/*    title={t('nav.assets.globalValues')}*/}
                {/*    to="/assets/global-value/overview"*/}
                {/*    activePath="/assets/global-value/"*/}
                {/*/>*/}
                <NavItem
                    title={t('nav.assets.city')}
                    to={`/assets/city/${store.currentCity.id}/edit`}
                    activePath="/assets/city/"
                />
                 <NavItem
                    title={t('nav.assets.email')}
                    to="/assets/email-log/overview"
                    activePath="/assets/email-log/"
                />
            </NavMenu>
        );
    };

    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    };

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    renderCityMenu = () => {
        const { location } = this.props;

        let locationIsSpecificCity = (location) => location.pathname.startsWith('/city/') && !location.pathname.includes('overview');
        let locationSplit = location.pathname.split('/');
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.header.overview')}
                    to="/city/overview"
                />
                {locationIsSpecificCity(location) && [
                    <NavItem
                        title={t('city.edit.title')}
                        to={`/${locationSplit[1]}/${locationSplit[2]}/edit/`}
                        active={locationSplit[3] === 'edit'}
                    />,
                    <NavItem
                        title={t('nav.assets.users')}
                        to={`/${locationSplit[1]}/${locationSplit[2]}/users/`}
                        active={locationSplit[3] === 'users'}
                    />
                ]}

            </NavMenu>
        );
    };

    renderEventMenu = () => {
        const { location } = this.props;
        let locationSplit = location.pathname.split('/').filter(path => path !== '')
        let locationIsSpecificEvent = (location) => location.pathname.startsWith('/event/') && locationSplit.length > 2;
        return (
            <NavMenu>
                {!locationIsSpecificEvent(location) && <NavItem
                    title={t('nav.header.overview')}
                    to="/event/overview"
                />
                }
                {locationIsSpecificEvent(location) && [
                    <NavItem
                        title={t('event.edit.settings')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/edit/`}
                    />,
                    <NavItem
                        title={t('event.edit.races')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/races/`}
                    />,
                    <NavItem
                        title={t('event.edit.tickets')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/tickets/`}
                    />,
                     <NavItem
                        title={t('event.edit.ducks')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/ducks/`}
                    />,
                     <NavItem
                        title={t('event.edit.prizes')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/prizes/`}
                    />,
                     <NavItem
                        title={t('event.edit.salesPartners')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/sales-partners/`}
                    />,
                    <NavItem
                        title={t('event.edit.salesPartnerGroups')}
                        to={`/${locationSplit[0]}/${locationSplit[1]}/sales-partner-groups/`}
                    />,

                ]}

            </NavMenu>
        );
    };


    render() {
        const { currentUser, currentEvent } = this.props.store;
        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <StyledMenuRow>
                        <MyLogo store={this.props.store}/>
                    </StyledMenuRow>
                    <StyledMenuRow2/>
                </TopMenu>
            );
        }

        const eventPath = currentUser.groupNames.includes('rfid_scanner') ? 'add-rfid-tags': 'scan-qr'

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames.includes('rfid_scanner') ||
            currentUser.groupNames.includes('ticket_scanner')
        )) {
            return (
                <TopMenu>
                    <StyledMenuRow>
                        <MyLogo store={this.props.store}/>
                        {/*<NavItem*/}
                        {/*    title={t('nav.main.event')}*/}
                        {/*    to={'/event/overview/'}*/}
                        {/*    activePath={'/event/overview'}*/}
                        {/*/>*/}

                        {[...this.shownEvents, (!this.activeEvents.get(currentEvent.id) && currentEvent)].map(activeEvent => activeEvent?.id && (
                            <NavItem
                                title={activeEvent.name}
                                to={`/event/${activeEvent.id}/${eventPath}/`}
                                activePath={`/event/${activeEvent.id}/`}
                            />
                        ))}
                    </StyledMenuRow>
                    <StyledMenuRow2/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size="small">
                <Header icon="archive" content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="red" inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name="remove"/> {t('form.no')}
                    </Button>
                    <Button color="green" inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name="checkmark"/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = (
            <TopMenu>
                <StyledMenuRow>
                    <MyLogo store={this.props.store}/>
                    <NavMenu>
                        {/*{currentUser.isSuperuser && <NavItem*/}
                        {/*    title={t('nav.assets.users')}*/}
                        {/*    to="/assets/user/overview"*/}
                        {/*    activePath="/assets/user/"*/}
                        {/*/>}*/}
                        <NavItem
                            title={t('nav.main.assets')}
                            to="/assets/user/overview/"
                            activePath={'/assets/'}
                        />
                        {currentUser.hasPermission('assets.resellermanage_city') &&
                            <NavItem
                                title={t('nav.main.city')}
                                to="/city/overview/"
                                activePath="/city/overview/"
                            />
                        }
                        <NavItem
                            title={t('nav.main.event')}
                            to={'/event/overview/'}
                            activePath={'/event/overview'}
                        />

                        {[...this.shownEvents, (!this.activeEvents.get(currentEvent.id) && currentEvent)].map(activeEvent => activeEvent?.id && (
                            <NavItem
                                title={activeEvent.name}
                                to={`/event/${activeEvent.id}/edit/`}
                                activePath={`/event/${activeEvent.id}/`}
                            />
                        ))}
                    </NavMenu>
                    {currentUser.isSuperuser && <NavMenu>
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                    </NavMenu>}
                    {this.renderAccountMenu()}
                </StyledMenuRow>
                <StyledMenuRow2>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/city" render={this.renderCityMenu}/>
                    <Route path="/event/" render={this.renderEventMenu}/>
                    <Route path="/audit/" render={this.renderAudit}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </StyledMenuRow2>
            </TopMenu>
        );

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
