import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Event } from './Event';


export class Prize extends Model {
    static backendResourceName = 'prize';
    // static fileFields = ['image']


    @observable id = null;
    @observable name = null;
    @observable value = null;
    @observable description = null;
    @observable image = null;


    relations() {
        return {
            event: Event,
        }
    }

    casts() {
        return {
            'image': Casts.file,
        };
    }
}

export class PrizeStore extends Store {
    static backendResourceName = 'prize';
    Model = Prize;
}
