import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Event } from './Event';
import { Casts } from 'mobx-spine';

export class Race extends Model {
    static backendResourceName = 'race';

    @observable id = null;
    @observable number = null;
    @observable date = null;
    @observable numOfDucks = null;

    casts() {
        return {
            date: Casts.date,
        };
    }

    relations() {
        return {
            event: Event,
        }
    }
}

export class RaceStore extends Store {
    static backendResourceName = 'race';
    Model = Race;
}
