import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(() => import('spider/semantic-ui/LoginForgot'));
const ResetPassword = Load(() => import('spider/semantic-ui/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const CityOverview = Load(() => import('../screen/City/CityOverview'));
const CityEdit = Load(() => import('../screen/City/CityEdit'));

const EventOverview = Load(() => import('../screen/Event/Overview'));
const EventEdit = Load(() => import('../screen/Event/Edit'));
const RaceOverview = Load(() => import('screen/Event/RaceOverview'));
const DuckOverview = Load(() => import('screen/Event/DuckOverview'));
const PrizeOverview = Load(() => import('screen/Event/PrizeOverview'));
const TicketOverview = Load(() => import('screen/Event/TicketOverview'));
const SalesPartnerOverview = Load(() => import('screen/Finance/SalesPartnerOverview'));
const SalesPartnerGroupOverview = Load(() => import('screen/Finance/SalesPartnerGroupOverview'));
const AddRFIDTags = Load(() => import('screen/Event/AddRFIDTags'));
// const RaceEdit = Load(() => import('../screen/Race/Edit'));

const PurchaseWidget = Load(() => import('../screen/PurchaseWidget/Widget'));
const PaymentSuccess = Load(() => import('../screen/PurchaseWidget/Success'));
const ClaimDuckPage = Load(() => import('screen/ClaimWidget/ClaimDuck'));
const TicketPurchaseWidget = Load(() => import('../screen/PurchaseWidget/TicketWidget'));

const SalesLeaderBoard = Load(() => import('screen/SalesLeaderBoard'));


const OrderDetails = Load(() => import('screen/Order/OrderDetails'));

const QrCodeScannerScreen = Load(() => import('screen/Order/QrCodeScannerScreen'))


const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/email-log/overview" render={this.route(EmailLogOverview)}/>

                <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>


                <Route path="/city/overview" render={this.route(CityOverview)}/>
                <Route path="/assets/city/:id/edit" render={this.route(CityEdit)}/>
                <Route path="/city/:id/edit/" render={this.route(CityEdit)}/>
                <Route path="/city/:city/users/" render={this.route(UserOverview)}/>
                <Route path="/city/:city/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/city/:city/user/add" render={this.route(UserEdit)}/>

                <Route path="/event/overview/" render={this.route(EventOverview)}/>
                <Route path="/event/add/" render={this.route(EventEdit)}/>
                <Route path="/event/:id/edit/" render={this.route(EventEdit)}/>
                <Route path="/event/:event/races/" render={this.route(RaceOverview)}/>
                <Route path="/event/:event/ducks/" render={this.route(DuckOverview)}/>
                <Route path="/event/:event/prizes/" render={this.route(PrizeOverview)}/>
                <Route path="/event/:event/tickets/" render={this.route(TicketOverview)}/>
                <Route path="/event/:event/sales-partners/" render={this.route(SalesPartnerOverview)}/>
                <Route path="/event/:event/sales-partner-groups/" render={this.route(SalesPartnerGroupOverview)}/>
                <Route path="/event/:event/add-rfid-tags/" render={this.route(AddRFIDTags)}/>

                <Route path="/event/:event/purchase_tickets" render={this.route(TicketPurchaseWidget)}/>
                <Route path="/event/:event/purchase_tickets/:sales_partner" render={this.route(TicketPurchaseWidget)}/>
                <Route path="/event/:event/purchase/:sales_partner" render={this.route(PurchaseWidget)}/>
                <Route path="/event/:event/purchase" render={this.route(PurchaseWidget)}/>

                <Route path="/event/:event/payment_success" render={this.route(PaymentSuccess)}/>
                <Route path="/event/:event/claim" render={this.route(ClaimDuckPage)}/>

                <Route path="/order/:id/details" render={this.route(OrderDetails)}/>
                <Route path="/scan-qr" render={this.route(QrCodeScannerScreen)}/>
                <Route path="/event/:event/scan-qr" render={this.route(QrCodeScannerScreen)}/>

                <Route path="/sales-leaderboard/:sales_group/" render={this.route(SalesLeaderBoard)}/>

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
